<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              內容管理中心
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'CollectionList' }">
              資料集管理
            </b-breadcrumb-item>

            <b-breadcrumb-item active>
              {{ collection.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">{{ collection.name }}</h4>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-tabs @input="handleUserChangesTab">
                <b-tab title="基本設定">
                  <div class="row d-flex mb-4 mb-xl-2 justify-content-end">
                    <div
                      class="
                        col-12 col-xl-6
                        d-flex
                        flex-column flex-xl-row
                        align-items-center justify-content-xl-end
                      "
                    >
                      <b-button
                        v-if="checkPermission([consts.COLLECTION_ITEM_CREATE])"
                        class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
                        variant="primary"
                        :to="{ name: 'CollectionItemCreate' }"
                      ><i class="fa fa-plus"></i>新增項目</b-button
                      >
                      <b-button
                        class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
                        v-if="(branchId == null) && showBranchSelect && checkPermission([consts.COLLECTION_ITEM_SYNC_BRANCH])"
                        @click="handleSyncCollectionToBranch"
                        variant="primary"
                      ><i class="mdi mdi-sync" aria-hidden="true"></i>同步至所有分店
                      </b-button>
                      <b-select
                        ref="items"
                        v-if="showBranchSelect && activeTabIndex == 0"
                        :options="branchOptions"
                        v-model="branchId"
                        @input="fetchData"
                        :disabled="!this.checkPermission([consts.COLLECTION_ITEM_LIST_BRANCH])"
                      ></b-select>
                      <b-input-group class="col-12 col-xl-4 mb-2">
                        <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
                        <b-input-group-append>
                          <b-button @click="fetchData"><i class="fa fa-search"></i ></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                  <b-table
                    striped
                    hover
                    responsive
                    :items="items"
                    :fields="fields"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        class="mr-1"
                        v-if="checkPermission([consts.COLLECTION_ITEM_EDIT])"
                        variant="inverse-warning"
                        :to="{ name: 'CollectionItemEdit', params: { id: collection.id, item_id: data.item.id }}"
                      >
                        <span class="mdi mdi-lead-pencil"></span> 編輯
                      </b-button>
                      <b-button
                        class="mr-1"
                        v-if="checkPermission([consts.COLLECTION_ITEM_DELETE])"
                        variant="inverse-danger"
                        @click="deleteItem(data.item)"
                      >
                        <span class="mdi mdi-delete"></span> 刪除
                      </b-button>
                    </template>
                    <template #cell()="data">
                      <template v-if="data.field.type === 'uploader'">
                        <a v-if="data.value" :href="data.value" target="_blank">（查看檔案）</a>
                        <template v-else>（無檔案）</template>
                      </template>
                      <template v-else-if="data.field.is_dynamic_generated">{{ truncateText(data.value, 15) }}</template>
                      <template v-else>{{ data.value }}</template>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="啟用管理" v-if="isRelatedBranch">
                  <div class="row d-flex mb-4 mb-xl-2 justify-content-end">
                    <div
                      class="
                        col-12 col-xl-6
                        d-flex
                        flex-column flex-xl-row
                        align-items-center justify-content-xl-end
                      "
                    >
                      <b-button
                        v-if="checkPermission([consts.COLLECTION_ITEM_CREATE]) && selectedItems.length > 0"
                        class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
                        variant="primary"
                        @click="changeRelationIsEnabled"
                      ><i class="fa fa-plus"></i>更新狀態</b-button
                      >
                      <b-button
                        class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
                        v-if="checkPermission([consts.COLLECTION_ITEM_CREATE])"
                        @click="handleSyncCollectionToSingleBranch"
                        variant="primary"
                      ><i class="mdi mdi-sync" aria-hidden="true"></i>立刻同步{{ collection.name }}
                      </b-button>
                      <b-select
                        ref="relastion"
                        v-if="showBranchSelect && activeTabIndex == 1"
                        :options="branchOptions"
                        v-model="branchId"
                        @input="fetchData"
                        :disabled="!this.checkPermission([consts.COLLECTION_ITEM_LIST_BRANCH])"
                      ></b-select>
                    </div>
                  </div>
                  <b-table
                    striped
                    hover
                    responsive
                    :items="items"
                    :fields="fields"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        class="mr-1"
                        v-if="checkPermission([consts.COLLECTION_ITEM_EDIT])"
                        variant="inverse-warning"
                        :to="{ name: 'CollectionItemStaffList', params: { id: collection.id, item_id: data.item.item_id }}"
                      >
                        <span class="mdi mdi-lead-pencil"></span> 員工列表
                      </b-button>
                    </template>
                    <template #head(checkbox)>
                      <b-form-checkbox
                        v-model="selectAll"
                        @change="toggleSelectAll"
                      >
                      </b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox
                        v-model="data.item.selected"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchData"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import { format } from "date-fns";
import collectionApi from "@/apis/collection";
import truncateText from "@/utils/truncateText"
import {mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      consts: consts,
      keyword: '',
      branchId: null,
      showLoading: false,
      showBranchSelect: false,
      fieldsInit: false,
      activeTabIndex: 0,
      fields: [],
      items: [],
      collection: {
        id: null,
        name: null,
        code: null,
        description: null,
        config: {
          fields: [],
        },
      },
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      formUrl: null,
      selectAll: false,
      isInitialized: false,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["currentBranch", "myBranches"]),
    selectedItems() {
      return this.items.filter((item) => item.selected).map((item) => item.id)
    },
    isRelatedBranch() {
      return this.collection?.config?.related_branch
    },
    isRelatedStaff() {
      return this.collection?.config?.related_staff
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
          value: null,
          text: '無分店'
        },
        ...options
      ]
      return options
    },
  },
  watch: {
    selectedItems: {
      handler(newVal) {
        this.selectAll = newVal.length === this.items.length;
      },
      deep: true,
    },
  },
  async mounted() {
    this.collection.id = this.$route.params.id;
    this.resetFields();
    await this.fetchCollection();
    if (this.showBranchSelect) {
      if (this.currentBranch) {
        this.branchId = this.currentBranch.id
      }
      if (this.$route.query.branch_id) {
        this.branchId = this.$route.query.branch_id
      }
    } else {
      this.fetchData()
    }
    this.isInitialized = true
  },
  methods: {
    truncateText,
    async fetchData() {
      if (this.activeTabIndex == 1) {
        await this.fetchRelationsItems()
      } else {
        await this.fetchItems();
      }
    },
    async handleUserChangesTab(tab) {
      if (this.activeTabIndex == tab || !this.isInitialized) {
        return
      }
      this.activeTabIndex = tab
      this.resetFields()
      await this.fetchCollection()
      this.fetchData()
    },
    toggleSelectAll() {
      this.items.forEach((item) => (item.selected = this.selectAll));
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    resetFields() {
      this.items = []
      this.page = 1
      this.fields = [
        {
          key: 'created_at',
          label: '建立時間',
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        {
          key: 'order',
          label: '排序',
          sortable: true,
        },
      ]
    },
    async fetchRelationsItems() {
      const response = await collectionApi.getRelationsItems(
        this.collection.id,
        this.branchId,
        {
          per_page: this.perPage,
          page: this.currentPage,
        }
      );
      this.items = response.data.data.map((item) => {
        let newItem = {
          ...item,
          selected: false
        };
        for (let i = 0; i < item.data.length; i ++) {
          const data = item.data[i];
          newItem[data.field_id] = data.value;
        }

        return newItem;
      });

      this.totalRows = response.data.data.total;
    },
    async fetchCollection () {
      try {
        this.showLoading = true;
        const response = await collectionApi.getCollection(this.collection.id);
        this.collection = response.data.data;

        if (!this.collection.config?.fields || this.collection.config.fields.length === 0) {
          this.$swal("提醒", "此資料集尚未設定欄位，須設定欄位後才能新增及編輯項目", "warning");
          this.$router.push({
            name: "CollectionList",
          });
          return;
        }
        if (this.collection.config.related_branch) {
          this.showBranchSelect = true;
        }

        let newField = this.collection.config.fields.map((field) => {
          return {
            key: field._id ?? field.config._id,
            label: field.title ?? field.config.title,
            sortable: true,
            type: field.type,
            is_dynamic_generated: true,
          };
        });

        this.fields.unshift(...newField);
        if (this.isRelatedBranch) {
          this.fields.unshift({
            key: 'branch.name',
            label: '分店名稱',
            formatter: (val) => {
              if (!val) return '總店'
              return val
            }
          });
        }
        this.fields.unshift({key: 'type',label: '分類'});
        if (this.activeTabIndex == 1) {
          this.fields.unshift(
            {
              key: 'is_enabled',
              label: '狀態',
              sortable: true,
              formatter: (value) => {
                return value ? '啟用中' : '關閉中';
              },
            });
          this.fields.unshift({key: 'checkbox',label: ''});

          if (this.isRelatedStaff) {
            this.fields.push({ key: 'actions', label: '管理' });
          }
        } else {
          this.fields.push({ key: 'actions', label: '管理' });
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchItems () {
      try {
        this.showLoading = true;
        const response = await collectionApi.getItems(this.collection.id, {
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
          branch_id: this.branchId,
        });

        this.items = response.data.data.map((item) => {
          let newItem = {
            ...item
          };
          for (let i = 0; i < item.data.length; i ++) {
            const data = item.data[i];
            newItem[data.field_id] = data.value;
          }

          return newItem;
        });

        this.totalRows = response.data.data.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集項目錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteItem(item) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除資料集",
          text: `是否要將此項目刪除？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (! result.value) {
          return
        }

        await collectionApi.deleteItem(this.collection.id, item.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchItems();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
    async handleSyncCollectionToSingleBranch() {
      if (!this.branchId) return;
      try {
        const result = await this.$swal({
          title: "同步資料集",
          text: `確定要將 ${this.collection.name} 同步至目前分店？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "同步",
          reverseButtons: true,
        })

        if (! result.value) {
          return;
        }

        const response = await collectionApi.syncToSingleBranch(this.collection.id,
        {
          branch_id: this.branchId
        });
        if (response.status === 200) {
          this.$swal('成功', '同步已開始進行', 'success');
          await this.fetchData();
        }
      } catch (error) {
        console.error(error);
        this.$swal("失敗", error.response.data.message, "同步觸發失敗。同步可能正在進行中，請稍候再試");
      } finally {
        this.showLoading = false;
      }
    },
    async handleSyncCollectionToBranch() {
      if (this.branchId) return;
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "同步資料集",
          text: `確定要將所有 ${this.collection.name} 同步至所有分店？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "同步",
          reverseButtons: true,
        })

        if (! result.value) {
          return;
        }

        const response = await collectionApi.syncToBranch({
          collection_id: this.collection.id,
        });
        if (response.status === 200) {
          this.$swal('成功', '同步已開始進行', 'success');
          await this.fetchData();
        }
      } catch (error) {
        console.error(error);
        this.$swal("失敗", error.response.data.message, "同步觸發失敗。同步可能正在進行中，請稍候再試");
      } finally {
        this.showLoading = false;
      }
    },
    async changeRelationIsEnabled() {
      if (!this.branchId) return;
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "調整更新狀態",
          text: `調整所選項目狀態為？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "狀態：關閉",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "狀態：啟用",
          reverseButtons: true,
          showCloseButton: true, // 顯示右上角的關閉按鈕
        })
        if (result.value || result.dismiss === 'cancel') { // 按啟用 || 按啟用
          const response = await collectionApi.changeRelationIsEnabled(
            this.collection.id,
            {
              item_ids: this.selectedItems,
              is_enabled: result.value ?? false
            }
          );
          if (response.status === 200) {
            this.$swal('成功', '更改狀態成功', 'success');
            await this.fetchData();
          }
        }
      } catch (error) {
        console.error(error);
        this.$swal("失敗", error.response.data.message, "更改狀態失敗，請稍候再試");
      } finally {
        this.showLoading = false;
      }
    }
  }
}
</script>

<style>
.custom-label {
  display: inline-flex; /* 只控制文字部分 */
  align-items: center; /* 垂直置中 */
  height: 100%; /* 繼承容器高度 */
  line-height: normal; /* 確保文字正常顯示 */
}
</style>
